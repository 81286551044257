//P1 images
import L1I1 from "../../../Assets/Leases/lease1_img1.jpg";
import L1I2 from "../../../Assets/Leases/lease1_img2.jpg";
import L1I3 from "../../../Assets/Leases/lease1_img3.jpg";
//P2 images
import L2I1 from "../../../Assets/Leases/lease2_img1.jpg";
import L2I2 from "../../../Assets/Leases/lease2_img2.jpg";
import L2I3 from "../../../Assets/Leases/lease2_img3.jpg";
//P3 images
import L3I1 from "../../../Assets/Leases/lease3_img1.jpg";
import L3I2 from "../../../Assets/Leases/lease3_img2.jpg";
//P4 images
import L4I1 from "../../../Assets/Leases/lease4_img1.jpg";
import L4I2 from "../../../Assets/Leases/lease4_img2.jpg";
//P5 images
import L5I1 from "../../../Assets/Leases/lease5_img1.jpg";
import L5I2 from "../../../Assets/Leases/lease5_img2.jpg";
//P6 images
import L6I1 from "../../../Assets/Leases/lease6_img1.jpg";
import L6I2 from "../../../Assets/Leases/lease6_img2.jpg";

import { IElementDataView } from "../Interface/IElementData.interface";

export const LeaseData: IElementDataView = {
    lease1: {
        images: [ L1I1, L1I2, L1I3 ],
        titleCode: "leasecard1name",
        textBlocks: [
            {
                isSmallTitle: true,
                title: "",
                text: "lease1TXT1"
            },
            {
                isSmallTitle: true,
                title: "",
                text: "lease1TXT2"
            },
            {
                isSmallTitle: true,
                title: "",
                text: "lease1TXT3"
            },
            {
                isSmallTitle: true,
                title: "",
                text: "lease1TXT4"
            },
            {
                isSmallTitle: true,
                title: "location",
                text: "leasecard1location"
            },
            {
                isSmallTitle: true,
                title: "totalBuiltinOffice",
                text: "leasecard1total_area"
            },
            {
                isSmallTitle: true,
                title: "floors",
                text: "leasecard1floors"
            },
            {
                isSmallTitle: true,
                title: "rentableArea",
                text: "leasecard1rentable_area"
            },
            {
                isSmallTitle: true,
                title: "dateOfCompletion",
                text: "leasecard1completion"
            },
            {
                isSmallTitle: true,
                title: "address",
                text: "lease1address"
            }
        ],
        coords: {
            lat: 47.4918333,
            lng: 21.6072222
        },
        path: "/lease/1",
        other1: "lease2",
        other2: "lease3",
        other3: "lease4",
        other4: "lease5",
        location: "lease1location"
    },
    lease2: {
        images: [ L2I1, L2I2, L2I3 ],
        titleCode: "leasecard2name",
        textBlocks: [
            {
                isSmallTitle: true,
                title: "",
                text: "lease2TXT1"
            },
            {
                isSmallTitle: true,
                title: "",
                text: "lease2TXT2"
            },
            {
                isSmallTitle: true,
                title: "",
                text: "lease2TXT3"
            },
            {
                isSmallTitle: true,
                title: "",
                text: "lease2TXT4"
            },
            {
                isSmallTitle: true,
                title: "",
                text: "lease2TXT5"
            },
            {
                isSmallTitle: true,
                title: "",
                text: "lease2TXT6"
            },
            {
                isSmallTitle: true,
                title: "location",
                text: "leasecard2location"
            },
            {
                isSmallTitle: true,
                title: "totalBuiltinWarehouse",
                text: "leasecard2total_area"
            },
            {
                isSmallTitle: true,
                title: "rentableArea",
                text: "leasecard2rentable_area"
            },
            {
                isSmallTitle: true,
                title: "dateOfCompletion",
                text: "leasecard2completion"
            },
            {
                isSmallTitle: true,
                title: "address",
                text: "lease2address"
            }
        ],
        coords: {
            lat: 47.493028,
            lng: 21.607194
        },
        path: "/lease/2",
        other1: "lease1",
        other2: "lease3",
        other3: "lease4",
        other4: "lease5"
    },
    lease3: {
        images: [ L3I1, L3I2 ],
        titleCode: "leasecard3name",
        textBlocks: [
            {
                isSmallTitle: true,
                title: "",
                text: "lease3TXT1"
            },
            {
                isSmallTitle: true,
                title: "",
                text: "lease3TXT2"
            },
            {
                isSmallTitle: true,
                title: "location",
                text: "leasecard3location"
            },
            {
                isSmallTitle: true,
                title: "totalBuiltinOffice",
                text: "leasecard3total_area"
            },

            {
                isSmallTitle: true,
                title: "floors",
                text: "leasecard3floors"
            },
            {
                isSmallTitle: true,
                title: "rentableArea",
                text: "leasecard3rentable_area"
            },
            {
                isSmallTitle: true,
                title: "dateOfCompletion",
                text: "leasecard3completion"
            },
            {
                isSmallTitle: true,
                title: "address",
                text: "lease3address"
            }
        ],
        coords: {
            lat: 47.523583,
            lng: 21.569222
        },
        path: "/lease/3",
        other1: "lease1",
        other2: "lease2",
        other3: "lease4",
        other4: "lease5"
    },
    lease4: {
        images: [ L4I1, L4I2 ],
        titleCode: "leasecard4name",
        textBlocks: [
            {
                isSmallTitle: true,
                title: "",
                text: "lease4TXT1"
            },
            {
                isSmallTitle: true,
                title: "",
                text: "lease4TXT2"
            },
            {
                isSmallTitle: true,
                title: "",
                text: "lease4TXT3"
            },
            {
                isSmallTitle: true,
                title: "",
                text: "lease4TXT4"
            },
            {
                isSmallTitle: true,
                title: "location",
                text: "leasecard4location"
            },
            {
                isSmallTitle: true,
                title: "totalBuiltinWarehouse",
                text: "leasecard4total_area"
            },
            {
                isSmallTitle: true,
                title: "rentableArea",
                text: "leasecard4rentable_area"
            },
            {
                isSmallTitle: true,
                title: "dateOfCompletion",
                text: "leasecard4completion"
            },
            {
                isSmallTitle: true,
                title: "address",
                text: "lease4address"
            }
        ],
        coords: {
            lat: 47.52375,
            lng: 21.566556
        },
        path: "/lease/4",
        other1: "lease1",
        other2: "lease2",
        other3: "lease3",
        other4: "lease5"
    },
    lease5: {
        images: [ L5I1, L5I2 ],
        titleCode: "leasecard5name",
        textBlocks: [
            {
                isSmallTitle: true,
                title: "",
                text: "lease5TXT1"
            },
            {
                isSmallTitle: true,
                title: "",
                text: "lease5TXT2"
            },
            {
                isSmallTitle: true,
                title: "location",
                text: "leasecard5location"
            },
            {
                isSmallTitle: true,
                title: "totalBuiltinWarehouse",
                text: "leasecard5total_area"
            },
            {
                isSmallTitle: true,
                title: "rentableArea",
                text: "leasecard5rentable_area"
            },
            {
                isSmallTitle: true,
                title: "address",
                text: "lease5address"
            }
        ],
        coords: {
            lat: 47.505722,
            lng: 21.527278
        },
        path: "/lease/5",
        other1: "lease1",
        other2: "lease2",
        other3: "lease3",
        other4: "lease4"
    },
    lease6: {
        images: [ L6I1, L6I2 ],
        titleCode: "leasecard6name",
        textBlocks: [
            {
                isSmallTitle: true,
                title: "",
                text: "lease6TXT1"
            },
            {
                isSmallTitle: true,
                title: "",
                text: "lease6TXT2"
            },
            {
                isSmallTitle: true,
                title: "",
                text: "lease6TXT3"
            },
            {
                isSmallTitle: true,
                title: "",
                text: "lease6TXT4"
            },
            {
                isSmallTitle: true,
                title: "location",
                text: "leasecard6location"
            },
            {
                isSmallTitle: true,
                title: "totalBuiltinWarehouse",
                text: "leasecard6total_area"
            },
            {
                isSmallTitle: true,
                title: "rentableArea",
                text: "leasecard6rentable_area"
            },
            {
                isSmallTitle: true,
                title: "address",
                text: "lease6address"
            }
        ],
        coords: {
            lat: 47.505722,
            lng: 21.527278
        },
        path: "/lease/5",
        other1: "lease1",
        other2: "lease2",
        other3: "lease3",
        other4: "lease4"
    }
};
