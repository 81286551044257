import React from "react";
import "./Less/VideoBlock.css";

export interface IVideoBlockViewProps {
    videoUrl: string;
    title: string;
}

const VideoBlockView: React.FC<IVideoBlockViewProps> = (props): React.ReactElement => {
    return (
        <div className="video-container">
            <div className="video-wrapper">
                <div className="video">
                    <iframe
                        title={props.title}
                        width="640"
                        height="360"
                        id="ytplayer"
                        frameBorder="0"
                        itemType="text/html"
                        src={`${props.videoUrl}?controls=1&autoplay=0`}
                    />
                </div>
            </div>
        </div>
    );
};

export default VideoBlockView;
