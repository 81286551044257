import React from "react";
import { ICoord } from "../Interface/IElementData.interface";
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import "./Less/Map.css";

interface IMapViewProps extends ILocationMapProps {
    googleMapURL: string;
    loadingElement: any;
    containerElement: any;
    mapElement: any;
    markerText: string;
}

const MapView = withScriptjs(
    withGoogleMap((props: IMapViewProps) => {
        return (
            <GoogleMap defaultZoom={14} defaultCenter={props.coords} center={props.coords}>
                <MarkerWithLabel
                    labelAnchor={{ x: 0, y: 0 }}
                    labelClass="map__label--marker"
                    position={props.coords}
                >
                    <div>{props.markerText}</div>
                </MarkerWithLabel>
            </GoogleMap>
        );
    })
);

interface ILocationMapProps {
    coords: ICoord;
    markerText: string;
}

const LocationOneMap: React.FC<ILocationMapProps> = (props): React.ReactElement => {
    return (
        <MapView
            loadingElement={<div className="map map__loading" />}
            containerElement={<div className="map map__container" />}
            mapElement={<div className="map map__element" />}
            googleMapURL={
                "https://maps.googleapis.com/maps/api/js?key=AIzaSyC1eTKGSIgQhfZ-rZezqK7RWoYS31S1f0Q&v=3.exp&libraries=geometry,drawing,places"
            }
            markerText={`${props.markerText.substring(0, 27)}...`}
            coords={props.coords}
        >
            {props.children}
        </MapView>
    );
};

export default LocationOneMap;
