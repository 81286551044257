import React from "react";
import { useTranslation } from "react-i18next";
import "./Css/MisuseReport.css";

export interface IMisuseReportProps {}

const MisuseReport: React.FC<IMisuseReportProps> = (props) => {
    const { t } = useTranslation();

    return (
        <div className="misuse-view">
            <div className="misuse-container">
                <div className="misuse-data-row">
                    <div className="name">
                        Tájékoztató a visszaélés-bejelentési rendszer működéséről 23.12.17
                    </div>
                    <a
                        href="/Tajekoztato_visszaeles_bejelentesi_rendszer_mukodeserol_23_12_17.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="misuse-data-row">
                    <div className="name">
                        Tájékoztató a visszaélés-bejelentési rendszer működéséről 1.sz. melléklet
                        23.12.17
                    </div>
                    <a
                        href="/Tajekoztato_visszaeles_bejelentesi_rendszer_mukodeserol_1_sz_melleklet_23_12_17.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="misuse-data-row">
                    <div className="name">
                        Tájékoztató a visszaélés-bejelentési rendszer működésével kapcsolatos
                        adatkezelései tevékenységről 23.12.17.
                    </div>
                    <a
                        href="/Tajekoztato_visszaeles_bejelentesi_rendszer_mukodesevel_kapcsolatos_adatkezelesi_tevekenysegrol_23_12_17.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default MisuseReport;
