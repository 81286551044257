import { IElementDataView } from "../Interface/IElementData.interface";

export const ApplicationData: IElementDataView = {
    application1: {
        images: [],
        titleCode: "application1",
        textBlocks: [
            {
                isSmallTitle: true,
                title: "applicationsSmallT9",
                text: "applicationsText9"
            },
            {
                isSmallTitle: true,
                title: "applicationsSmallT10",
                text: "applicationsText10"
            },
            {
                isSmallTitle: true,
                title: "applicationsSmallT11",
                text: "applicationsText11"
            },
            {
                isSmallTitle: true,
                title: "applicationsSmallT12",
                text: "applicationsText12"
            },
            {
                isSmallTitle: true,
                title: "applicationsSmallT13",
                text: "applicationsText13"
            },
            {
                isSmallTitle: true,
                title: "applicationsSmallT14",
                text: "applicationsText14"
            },
            {
                isSmallTitle: true,
                title: "applicationsSmallT15",
                text: "applicationsText15"
            },
            {
                isSmallTitle: true,
                title: "applicationsSmallT16",
                text: "applicationsText16"
            },
            {
                isSmallTitle: true,
                title: "applicationsSmallT17",
                text: "applicationsText17"
            },
            {
                isSmallTitle: true,
                title: "applicationsSmallT18",
                text: "applicationsText18"
            },
            {
                isSmallTitle: true,
                title: "applicationsSmallT19",
                text: "applicationsText19"
            }
        ],

        path: "/application/1",
        other1: "application3",
        other2: "application1"
    },
    application3: {
        images: [],
        titleCode: "application3",
        textBlocks: [
            {
                isSmallTitle: true,
                title: "applicationsSmallT20",
                text: "applicationsText20"
            },
            {
                isSmallTitle: true,
                title: "applicaitonsSmallT21",
                text: "applicationsText21"
            },
            {
                isSmallTitle: true,
                title: "applicationsSmallT22",
                text: "applicationsText22"
            }
        ],
        path: "/application/2",
        other1: "application1",
        other2: "application3"
    },
    application2: {
        images: [],
        titleCode: "application2",
        textBlocks: [
            {
                isSmallTitle: true,
                title: "applicationsSmallT2",
                text: "applicationsText1"
            },
            {
                isSmallTitle: true,
                title: "applicationsSmallT3",
                text: "applicationsText2"
            },
            {
                isSmallTitle: true,
                title: "applicationsSmallT4",
                text: "applicationsText4"
            },
            {
                isSmallTitle: true,
                title: "applicationsSmallT5",
                text: "applicationsText5"
            },
            {
                isSmallTitle: true,
                title: "applicationsSmallT6",
                text: "applicationsText6"
            },
            {
                isSmallTitle: true,
                title: "applicationsSmallT7",
                text: "applicationsText7"
            },
            {
                isSmallTitle: true,
                title: "applicationsSmallT8",
                text: "applicationsText8"
            }
        ],
        path: "/application/3",
        other1: "application1",
        other2: "application2"
    }
};
