import * as React from "react";
import { Leases } from "../Static/Lease.static";
import { IHistory } from "xa-generics";
import { useHistory } from "react-router-dom";
import { ILeaseCard } from "../Interface/ILeaseCard.interface";
import { useTranslation } from "react-i18next";
import RequestForQuotation from "../../RequestForQuotation/View/RequestForQuotation";
import Spinner from "../../Spinner/Spinner";
import Image from "react-image";
import "./Less/Lease.css";
export interface LeaseViewProps {}

const LeaseView: React.SFC<LeaseViewProps> = (props) => {
    const { t } = useTranslation();
    const history: IHistory = useHistory();
    return (
        <div className="lease-wrapper">
            <div className="lease">
                <div className="lease__title">{t("leaseTitle")}</div>
                <div className="lease__desc">
                    <p>{t("leaseDesc1")}</p>
                    <p>{t("leaseDesc2")}</p>
                    <p>{t("leaseDesc3")}</p>
                    <RequestForQuotation />
                </div>
                <div className="lease__container">
                    {Leases.map((lease: ILeaseCard, index: number) => (
                        <div
                            className="card-container"
                            key={`${index}-lease-card`}
                            onClick={() => history.push(`/lease/${lease.id}`)}
                        >
                            <div className="lease-card">
                                <div className="lease-card__header">{t(lease.name)}</div>
                                <div className="lease-card__image">
                                    <Image
                                        src={lease.images[0]}
                                        alt={lease.name}
                                        loader={<Spinner />}
                                        className="lease-card__image--element"
                                    />
                                    <div className="lease-card__sub-title">{t(lease.location)}</div>
                                </div>
                                <div className="lease-card__body">
                                    {lease.total_warehouse_area && (
                                        <div className="lease-card__body--total-area">
                                            <p className="lease-card__body--label">
                                                {t("totalBuiltinWarehouse")}
                                            </p>
                                            <p className="lease-card__body--data">
                                                {t(lease.total_warehouse_area)}
                                            </p>
                                        </div>
                                    )}
                                    {lease.total_office_area && (
                                        <div className="lease-card__body--total-area">
                                            <p className="lease-card__body--label">
                                                {t("totalBuiltinOffice")}
                                            </p>
                                            <p className="lease-card__body--data">
                                                {t(lease.total_office_area)}
                                            </p>
                                        </div>
                                    )}
                                    <div className="lease-card__body--company">
                                        <p className="lease-card__body--label">Rentable area</p>
                                        <p className="lease-card__body--data">
                                            {t(lease.rentable_area)}
                                        </p>
                                    </div>
                                    {lease.floors && (
                                        <div className="lease-card__body--date">
                                            <p className="lease-card__body--label">{t("floors")}</p>
                                            <p className="lease-card__body--data">
                                                {t(lease.floors)}
                                            </p>
                                        </div>
                                    )}
                                    {lease.completion && (
                                        <div className="lease-card__body--date">
                                            <p className="lease-card__body--label">
                                                Date of completion
                                            </p>
                                            <p className="lease-card__body--data">
                                                {t(lease.completion)}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className="lease-card__footer">
                                    <div className="arrow-root">
                                        <span className="arrow" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LeaseView;
