import * as React from "react";
import LeaseView from "../View/Lease.view";

export interface LeaseControllerProps {}

const LeaseController: React.SFC<LeaseControllerProps> = (props) => {
    return <LeaseView />;
};

export default LeaseController;
