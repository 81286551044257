import React, { useContext, useState } from "react";
import { sortBy } from "lodash";
import { IHistory } from "xa-generics";
import { useHistory } from "react-router";
import { DisplayLang } from "../Static/DisplayLang.static";
import { useTranslation } from "react-i18next";
import { AccessModel, AccessContext } from "access-factory";
import { INavElement, NavigationFactory } from "xa-navigation";
import Cookie from "js-cookie";
import Logo from "../../../Assets/logo_white.png";
import "./Less/Toolbar.css";

export interface IToolbarViewProps {}

const ToolbarView: React.FC<IToolbarViewProps> = (props): React.ReactElement => {
    const [opened, set_opened] = useState<boolean>(false);
    const access: AccessModel = useContext(AccessContext).accessLevel;
    const history: IHistory<void> = useHistory();

    const routes: INavElement[] = sortBy(NavigationFactory.getMyRoutes(access), "sort_order");
    const { t, i18n } = useTranslation();

    const classes: string[] = ["navbar"];
    if (opened) classes.push("navbar-open");

    const handleNavElementClick = (route: string): void => {
        history.push(route);
        if (opened) set_opened(false);
    };

    const changeLanguage = (): void => {
        set_opened(false);
        if (i18n.language === "hu") {
            Cookie.set("lang", "en");
            i18n.changeLanguage("en");
            return;
        }
        if (i18n.language === "en") {
            Cookie.set("lang", "hu");
            i18n.changeLanguage("hu");
            return;
        }
    };

    return (
        <div className={classes.join(" ")}>
            <div className="navbar__group-name">XANGA</div>
            <div className="navbar__wrapper">
                <div className="navbar__brand" onClick={() => handleNavElementClick("/")}>
                    <img
                        src={Logo}
                        alt="XANGA"
                        className="navbar__brand--logo"
                        onClick={() => history.push("/")}
                    />
                </div>
                <div className="navbar__content">
                    {routes.map((nav: INavElement, index: number) => (
                        <div
                            className="navbar__content--route"
                            key={`${index}-nav-route-${nav.route}`}
                            onClick={() => handleNavElementClick(nav.route)}
                        >
                            {t(nav.text)}
                        </div>
                    ))}
                    <div
                        className="navbar__content--route dark-background-nav"
                        onClick={changeLanguage}
                    >
                        {DisplayLang[i18n.language]}
                    </div>
                </div>
            </div>
            <div className="backdrop" onClick={() => set_opened(false)} />
            <div className="threads" onClick={() => set_opened(!opened)}>
                <div className="thread" />
                <div className="thread" />
                <div className="thread" />
            </div>
        </div>
    );
};

export default ToolbarView;
