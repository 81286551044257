import { ITimeline } from "./../Interface/ITimeline.interface";

export const Timelines: ITimeline[] = [
    {
        date: "1998",
        text: "timeline1"
    },
    {
        date: "2001",
        text: "timeline2"
    },
    {
        date: "2006",
        text: "timeline3"
    },
    {
        date: "2008",
        text: "timeline4"
    },
    {
        date: "2009",
        text: "timeline5"
    },
    {
        date: "2010",
        text: "timeline6"
    },
    {
        date: "2011",
        text: "timeline7"
    },
    {
        date: "2012",
        text: "timeline8"
    },
    {
        date: "2013",
        text: "timeline9"
    },
    {
        date: "2014",
        text: "timeline10"
    },
    {
        date: "2015",
        text: "timeline11"
    },
    {
        date: "2015",
        text: "timeline12"
    },
    {
        date: "2016",
        text: "timeline13"
    },
    {
        date: "2017",
        text: "timeline14"
    },
    {
        date: "2018",
        text: "timeline15"
    },
    {
        date: "2019",
        text: "timeline16"
    }
];
