import React from "react";
import { useHistory } from "react-router-dom";
import { IHistory } from "xa-generics";
import "./Less/SimplePDFPage.view.css";

export interface IE404Props {}

const SimplePDFPage: React.FC<IE404Props> = (props): React.ReactElement => {
    const history: IHistory<void> = useHistory();
    let src = "./richtergedeon.pdf";
    if (history.location.pathname.includes("hatarut")) src = "./hatarut.pdf";
    return (
        <div className="pdf-page-component">
            <iframe className="content" allowFullScreen src={src} title="PDF_Page" />;
        </div>
    );
};

export default SimplePDFPage;
