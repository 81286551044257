//P1 images
import L1I1 from "./lease1_img1.jpg";
import L1I2 from "./lease1_img2.jpg";
import L1I3 from "./lease1_img3.jpg";
import L1I4 from "./lease1_img4.jpg";
import L1I5 from "./lease1_img5.jpg";
//P2 images
import L2I1 from "./lease2_img1.jpg";
import L2I2 from "./lease2_img2.jpg";
import L2I3 from "./lease2_img3.jpg";
import L2I4 from "./lease2_img4.jpg";
import L2I5 from "./lease2_img5.jpg";
import L2I6 from "./lease2_img6.jpg";
import L2I7 from "./lease2_img7.jpg";
import L2I8 from "./lease2_img8.jpg";
import L2I9 from "./lease2_img9.jpg";
import L2I10 from "./lease2_img10.jpg";
import L2I11 from "./lease2_img11.jpg";
import L2I12 from "./lease2_img12.jpg";
import L2I13 from "./lease2_img13.jpg";
//P3 images
import L3I1 from "./lease3_img1.jpg";
import L3I2 from "./lease3_img2.jpg";
//P4 images
import L4I1 from "./lease4_img1.jpg";
import L4I2 from "./lease4_img2.jpg";
//P5 images
import L5I1 from "./lease5_img1.jpg";
import L5I2 from "./lease5_img2.jpg";
//P6 images
import L6I1 from "./lease6_img1.jpg";
import L6I2 from "./lease6_img2.jpg";

export const LeaseImages = {
    L1: [L1I1, L1I2, L1I3, L1I4, L1I5],
    L2: [L2I1, L2I2, L2I3, L2I4, L2I5, L2I6, L2I7, L2I8, L2I9, L2I10, L2I11, L2I12, L2I13],
    L3: [L3I1, L3I2],
    L4: [L4I1, L4I2],
    L5: [L5I1, L5I2],
    L6: [L6I1, L6I2]
};
