import React from "react";
import Image from "react-image";
import Laurel from "../../../Assets/laurel.png";
import { useTranslation } from "react-i18next";
import { Awards } from "../Static/Awards.static";
import { IAward } from "../Interface/IAward.interface";
import "./Less/Awards.css";

const AwardsView: React.FC<{}> = (): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="awards" id="awards">
            <div className="awards__title">{t("awards")}</div>
            <div className="awards__container">
                {Awards.map((award: IAward, index: number) => (
                    <div className="awards__box" key={`${index}-award`}>
                        <Image
                            src={Laurel}
                            alt="Failed to load image"
                            className="awards__box--laurel"
                        />
                        <div className="awards__box--header">{award.date}</div>
                        <div className="awards__box--title">{t(award.title)}</div>
                        <div className="awards__box--text">{t(award.description)}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AwardsView;
