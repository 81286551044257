//Location 1 images
import Loc1Img1 from "../../../Assets/Locations/loc1_img1.jpg";
import Loc1Img2 from "../../../Assets/Locations/loc1_img2.jpg";
import Loc1Img3 from "../../../Assets/Locations/loc1_img3.jpg";
import Loc1Img4 from "../../../Assets/Locations/loc1_img4.jpg";
import Loc1Img5 from "../../../Assets/Locations/loc1_img5.jpg";
import Loc1Img6 from "../../../Assets/Locations/loc1_img6.jpg";
import Loc1Img7 from "../../../Assets/Locations/loc1_img7.jpg";
import Loc1Img8 from "../../../Assets/Locations/loc1_img8.jpg";
import Loc1Img9 from "../../../Assets/Locations/loc1_img9.jpg";
import Loc1Img10 from "../../../Assets/Locations/loc1_img10.jpg";
import Loc1Img11 from "../../../Assets/Locations/loc1_img11.jpg";
import Loc1Img12 from "../../../Assets/Locations/loc1_img12.jpg";
import Loc1Img13 from "../../../Assets/Locations/loc1_img13.jpg";
import Loc1Img14 from "../../../Assets/Locations/loc1_img14.jpg";
import Loc1Img15 from "../../../Assets/Locations/loc1_img15.jpg";
import Loc1Img16 from "../../../Assets/Locations/loc1_img16.jpg";
import Loc1Img17 from "../../../Assets/Locations/loc1_img17.jpg";
import Loc1Img18 from "../../../Assets/Locations/loc1_img18.jpg";
import Loc1Img19 from "../../../Assets/Locations/loc1_img19.jpg";
import Loc1Img20 from "../../../Assets/Locations/loc1_img20.jpg";
import Loc1Img21 from "../../../Assets/Locations/loc1_img21.jpg";
import Loc1Img22 from "../../../Assets/Locations/loc1_img22.jpg";
import Loc1Img23 from "../../../Assets/Locations/loc1_img23.jpg";
import Loc1Img24 from "../../../Assets/Locations/loc1_img24.jpg";
import Loc1Img25 from "../../../Assets/Locations/loc1_img25.jpg";
import Loc1Img26 from "../../../Assets/Locations/loc1_img26.jpg";
import Loc1Img27 from "../../../Assets/Locations/loc1_img27.jpg";
import Loc1Img28 from "../../../Assets/Locations/loc1_img28.jpg";
import Loc1Img29 from "../../../Assets/Locations/loc1_img29.jpg";
import Loc1Img30 from "../../../Assets/Locations/loc1_img30.jpg";
import Loc1Img31 from "../../../Assets/Locations/loc1_img31.jpg";
import Loc1Img32 from "../../../Assets/Locations/loc1_img32.jpg";
import Loc1Img33 from "../../../Assets/Locations/loc1_img33.jpg";
import Loc1Img34 from "../../../Assets/Locations/loc1_img34.jpg";
import Loc1Img35 from "../../../Assets/Locations/loc1_img35.jpg";

//Location 2 images
import Loc2Img1 from "../../../Assets/Locations/loc2_img1.jpg";
import Loc2Img2 from "../../../Assets/Locations/loc2_img2.jpg";
import Loc2Img3 from "../../../Assets/Locations/loc2_img3.jpg";
import Loc2Img4 from "../../../Assets/Locations/loc2_img4.jpg";
import Loc2Img5 from "../../../Assets/Locations/loc2_img5.jpg";
import Loc2Img6 from "../../../Assets/Locations/loc2_img6.jpg";
import Loc2Img7 from "../../../Assets/Locations/loc2_img7.jpg";
import Loc2Img8 from "../../../Assets/Locations/loc2_img8.jpg";
import Loc2Img9 from "../../../Assets/Locations/loc2_img9.jpg";
import Loc2Img10 from "../../../Assets/Locations/loc2_img10.jpg";
import Loc2Img11 from "../../../Assets/Locations/loc2_img11.jpg";
import Loc2Img12 from "../../../Assets/Locations/loc2_img12.jpg";
import Loc2Img13 from "../../../Assets/Locations/loc2_img13.jpg";
import Loc2Img14 from "../../../Assets/Locations/loc2_img14.jpg";
import Loc2Img15 from "../../../Assets/Locations/loc2_img15.jpg";
import Loc2Img16 from "../../../Assets/Locations/loc2_img16.jpg";
import Loc2Img17 from "../../../Assets/Locations/loc2_img17.jpg";
//Location 3 images
import Loc3Img1 from "../../../Assets/Locations/loc3_img2.jpg";
import Loc3Img2 from "../../../Assets/Locations/loc3_img3.jpg";
import Loc3Img3 from "../../../Assets/Locations/loc3_img4.jpg";
import Loc3Img4 from "../../../Assets/Locations/loc3_img4.jpg";
import Loc3Img5 from "../../../Assets/Locations/loc3_img5.jpg";
import Loc3Img6 from "../../../Assets/Locations/loc3_img6.jpg";
import Loc3Img7 from "../../../Assets/Locations/loc3_img7.jpg";
import Loc3Img8 from "../../../Assets/Locations/loc3_img8.jpg";
import Loc3Img9 from "../../../Assets/Locations/loc3_img9.jpg";
import Loc3Img10 from "../../../Assets/Locations/loc3_img10.jpg";
import Loc3Img11 from "../../../Assets/Locations/loc3_img11.jpg";
import Loc3Img12 from "../../../Assets/Locations/loc3_img12.jpg";
import Loc3Img13 from "../../../Assets/Locations/loc3_img13.jpg";
import Loc3Img14 from "../../../Assets/Locations/loc3_img14.jpg";
import Loc3Img15 from "../../../Assets/Locations/loc3_img15.jpg";
import Loc3Img16 from "../../../Assets/Locations/loc3_img16.jpg";
import Loc3Img17 from "../../../Assets/Locations/loc3_img17.jpg";
import Loc3Img18 from "../../../Assets/Locations/loc3_img18.jpg";
import Loc3Img19 from "../../../Assets/Locations/loc3_img19.jpg";
import Loc3Img20 from "../../../Assets/Locations/loc3_img20.jpg";
import Loc3Img21 from "../../../Assets/Locations/loc3_img21.jpg";
import Loc3Img22 from "../../../Assets/Locations/loc3_img22.jpg";
import Loc3Img23 from "../../../Assets/Locations/loc3_img23.jpg";
import Loc3Img24 from "../../../Assets/Locations/loc3_img24.jpg";
import Loc3Img25 from "../../../Assets/Locations/loc3_img25.jpg";
import Loc3Img26 from "../../../Assets/Locations/loc3_img26.jpg";
import Loc3Img27 from "../../../Assets/Locations/loc3_img27.jpg";
import Loc3Img28 from "../../../Assets/Locations/loc3_img28.jpg";
import Loc3Img29 from "../../../Assets/Locations/loc3_img29.jpg";
import Loc3Img30 from "../../../Assets/Locations/loc3_img30.jpg";
import Loc3Img31 from "../../../Assets/Locations/loc3_img31.jpg";
import Loc3Img32 from "../../../Assets/Locations/loc3_img32.jpg";
import Loc3Img33 from "../../../Assets/Locations/loc3_img33.jpg";
import Loc3Img34 from "../../../Assets/Locations/loc3_img34.jpg";
import Loc3Img35 from "../../../Assets/Locations/loc3_img35.jpg";
//Location 4 images
import Loc4Img1 from "../../../Assets/Locations/loc4_img1.jpg";
import Loc4Img2 from "../../../Assets/Locations/loc4_img2.jpg";
import Loc4Img3 from "../../../Assets/Locations/loc4_img3.jpg";

//Location 5 images
import Loc5Img1 from "../../../Assets/Locations/loc5_img1.png";

//Location 6 Images
import Loc6Img1 from "../../../Assets/Locations/loc6_img1.jpg";
import Loc6Img2 from "../../../Assets/Locations/loc6_img2.jpg";
import Loc6Img3 from "../../../Assets/Locations/loc6_img3.jpg";
import Loc6Img4 from "../../../Assets/Locations/loc6_img4.jpg";
import Loc6Img5 from "../../../Assets/Locations/loc6_img5.jpg";
import Loc6Img6 from "../../../Assets/Locations/loc6_img6.jpg";
import Loc6Img7 from "../../../Assets/Locations/loc6_img7.jpg";
import Loc6Img8 from "../../../Assets/Locations/loc6_img8.jpg";
import Loc6Img9 from "../../../Assets/Locations/loc6_img9.jpg";
import Loc6Img10 from "../../../Assets/Locations/loc6_img10.jpg";
import Loc6Img11 from "../../../Assets/Locations/loc6_img11.jpg";
import Loc6Img12 from "../../../Assets/Locations/loc6_img12.jpg";
import Loc6Img13 from "../../../Assets/Locations/loc6_img13.jpg";
import Loc6Img14 from "../../../Assets/Locations/loc6_img14.jpg";

//Location 7 Images
import Loc7Img1 from "../../../Assets/Locations/loc7_img1.jpg";
import Loc7Img2 from "../../../Assets/Locations/loc7_img2.jpg";
import Loc7Img3 from "../../../Assets/Locations/loc7_img3.jpg";
import Loc7Img4 from "../../../Assets/Locations/loc7_img4.jpg";
import Loc7Img5 from "../../../Assets/Locations/loc7_img5.jpg";
import Loc7Img6 from "../../../Assets/Locations/loc7_img6.jpg";
import Loc7Img7 from "../../../Assets/Locations/loc7_img7.jpg";
import Loc7Img8 from "../../../Assets/Locations/loc7_img8.jpg";
import Loc7Img9 from "../../../Assets/Locations/loc7_img9.jpg";
import Loc7Img10 from "../../../Assets/Locations/loc7_img10.jpg";
import Loc7Img11 from "../../../Assets/Locations/loc7_img11.jpg";
import Loc7Img12 from "../../../Assets/Locations/loc7_img12.jpg";
import Loc7Img13 from "../../../Assets/Locations/loc7_img13.jpg";
import Loc7Img14 from "../../../Assets/Locations/loc7_img14.jpg";

//Logos
import assaAbloy from "../../../Assets/Partners/AssaAbloy.png";
import colaLogo from "../../../Assets/Partners/cola_logo.png";
import dpd from "../../../Assets/Partners/dpd.png";
import gravic from "../../../Assets/Partners/Gravic.png";
import lufthansa from "../../../Assets/Partners/lufthansa.png";
import manz from "../../../Assets/Partners/manz.png";
import orelikon from "../../../Assets/Partners/orelikon.png";
import orszagosDohanyboltellato from "../../../Assets/Partners/orszagos_dohanyboltellato.png";
import dunaital from "../../../Assets/Partners/dunaital.png";
import rrd from "../../../Assets/Partners/RRD.png";
import transcomos from "../../../Assets/Partners/Transcomos.png";
import vizmuvek from "../../../Assets/Partners/vizmuvek.png";
import ni from "../../../Assets/Partners/ni.png";
import rg from "../../../Assets/Partners/rg.png";
import schaeffler from "../../../Assets/Partners/schaeffler.png";
import pp from "../../../Assets/Partners/pp.png";
import popz from "../../../Assets/Partners/popz.png";
import burkle from "../../../Assets/Partners/burkle.png";
import mst from "../../../Assets/Partners/mst.png";
import pannon from "../../../Assets/Partners/pannon.png";
import graphart from "../../../Assets/Partners/graphart.png";
import wizz from "../../../Assets/Partners/Wizz.png";
import sensirion from "../../../Assets/Partners/sensirion.png";
import univilltrade from "../../../Assets/Partners/univilltrade.png";
import pearlenterprises from "../../../Assets/Partners/pearlenterprises.jpeg";
import intertractionelectrics from "../../../Assets/Partners/itkintertractionelectrics.png";
import itk from "../../../Assets/Partners/itk.jpeg";

import { IElementDataView } from "../Interface/IElementData.interface";

export const LocationData: IElementDataView = {
    location1: {
        images: [
            Loc1Img1,
            Loc1Img2,
            Loc1Img3,
            Loc1Img4,
            Loc1Img5,
            Loc1Img6,
            Loc1Img7,
            Loc1Img8,
            Loc1Img9,
            Loc1Img10,
            Loc1Img11,
            Loc1Img12,
            Loc1Img13,
            Loc1Img14,
            Loc1Img15,
            Loc1Img16,
            Loc1Img17,
            Loc1Img18,
            Loc1Img19,
            Loc1Img20,
            Loc1Img21,
            Loc1Img22,
            Loc1Img23,
            Loc1Img24,
            Loc1Img25,
            Loc1Img26,
            Loc1Img27,
            Loc1Img28,
            Loc1Img29,
            Loc1Img30,
            Loc1Img31,
            Loc1Img32,
            Loc1Img33,
            Loc1Img34,
            Loc1Img35
        ],
        titleCode: "loc1Title",
        textBlocks: [
            {
                title: "",
                text: "loc1TXT1"
            },
            {
                title: "",
                text: "loc1TXT2"
            },
            {
                title: "",
                text: "loc1TXT3"
            },
            {
                title: "loc1SMT4",
                text: "loc1TXT4"
            },
            {
                title: "loc1SMT5",
                text: "loc1TXT5"
            }
        ],
        coords: {
            lat: 47.520054,
            lng: 21.574851
        },
        path: "/locations/1",
        other1: "location2",
        other2: "location3",
        other3: "location4",
        logos: {
            title: "location1LogosTitle",
            images: [
                orelikon,
                manz,
                schaeffler,
                ni,
                rg,
                assaAbloy,
                transcomos,
                rrd,
                gravic,
                pp,
                popz,
                mst,
                burkle,
                sensirion,
                univilltrade,
                pearlenterprises,
                intertractionelectrics,
                itk
            ]
        }
    },
    location2: {
        images: [
            Loc2Img1,
            Loc2Img3,
            Loc2Img2,
            Loc2Img4,
            Loc2Img5,
            Loc2Img6,
            Loc2Img7,
            Loc2Img8,
            Loc2Img9,
            Loc2Img10,
            Loc2Img11,
            Loc2Img12,
            Loc2Img13,
            Loc2Img14,
            Loc2Img15,
            Loc2Img16,
            Loc2Img17
        ],
        titleCode: "loc2Title",
        textBlocks: [
            {
                title: "",
                text: "loc2TXT1"
            },
            {
                title: "loc2SMT2",
                text: "https://www.xponential.hu",
                isLink: true
            }
        ],
        coords: {
            lat: 47.52895,
            lng: 21.623104
        },
        path: "/locations/2",
        other1: "location1",
        other2: "location3",
        other3: "location4"
    },
    location3: {
        images: [
            Loc3Img1,
            Loc3Img2,
            Loc3Img3,
            Loc3Img4,
            Loc3Img5,
            Loc3Img6,
            Loc3Img7,
            Loc3Img8,
            Loc3Img9,
            Loc3Img10,
            Loc3Img11,
            Loc3Img12,
            Loc3Img13,
            Loc3Img14,
            Loc3Img15,
            Loc3Img16,
            Loc3Img17,
            Loc3Img18,
            Loc3Img19,
            Loc3Img20,
            Loc3Img21,
            Loc3Img22,
            Loc3Img23,
            Loc3Img24,
            Loc3Img25,
            Loc3Img26,
            Loc3Img27,
            Loc3Img28,
            Loc3Img29,
            Loc3Img30,
            Loc3Img31,
            Loc3Img32,
            Loc3Img33,
            Loc3Img34,
            Loc3Img35
        ],
        titleCode: "loc3Title",
        textBlocks: [
            {
                title: "",
                text: "loc3TXT1"
            },
            {
                title: "",
                text: "loc3TXT2"
            },
            {
                title: "",
                text: "loc3TXT3"
            },
            {
                title: "loc3SMT4",
                text: "loc3TXT4"
            }
        ],
        coords: {
            lat: 47.49225,
            lng: 21.607159
        },
        path: "/locations/3",
        other1: "location1",
        other2: "location2",
        other3: "location4",
        logos: {
            title: "location3LogosTitle",
            images: [
                wizz,
                lufthansa,
                dpd,
                pannon,
                graphart,
                vizmuvek,
                colaLogo,
                orszagosDohanyboltellato,
                dunaital
            ]
        }
    },
    location4: {
        images: [Loc4Img2, Loc4Img1, Loc4Img3],
        titleCode: "loc4Title",
        textBlocks: [
            {
                title: "",
                text: "loc4TXT1"
            },
            {
                title: "",
                text: "loc4TXT2"
            },
            {
                title: "",
                text: "loc4TXT3"
            }
        ],
        coords: {
            lat: 47.480623,
            lng: 21.628958
        },
        path: "/locations/4",
        other1: "location1",
        other2: "location2",
        other3: "location3"
    },
    location5: {
        images: [Loc5Img1],
        titleCode: "loc5Title",
        textBlocks: [
            {
                title: "",
                text: "loc5TXT1"
            },
            {
                title: "",
                text: "loc5TXT2"
            },
            {
                title: "",
                text: "loc5TXT3"
            },
            {
                title: "loc5SMT4",
                text: "https://www.xangaventures.com",
                isLink: true
            }
        ],
        path: "/locations/5",
        other1: "location1",
        other2: "location2",
        other3: "location3",
        other4: "location4"
    },
    location6: {
        images: [
            Loc6Img1,
            Loc6Img2,
            Loc6Img3,
            Loc6Img4,
            Loc6Img5,
            Loc6Img6,
            Loc6Img7,
            Loc6Img8,
            Loc6Img9,
            Loc6Img10,
            Loc6Img11,
            Loc6Img12,
            Loc6Img13,
            Loc6Img14
        ],
        titleCode: "location6",
        textBlocks: [
            {
                title: "",
                text: "https://maps.app.goo.gl/W5d9CMPgh5U6jJZL8",
                isLink: true
            },
            {
                title: "",
                text: ""
            },
            {
                title: "",
                text: ""
            },
            {
                title: "",
                text: ""
            }
        ],
        coords: {
            lat: 48.0544107,
            lng: 20.8094502
        },
        path: "/locations/6",
        other1: "location1",
        other2: "location2",
        other3: "location3",
        other4: "location4"
    },
    location7: {
        images: [
            Loc7Img1,
            Loc7Img2,
            Loc7Img3,
            Loc7Img4,
            Loc7Img5,
            Loc7Img6,
            Loc7Img7,
            Loc7Img8,
            Loc7Img9,
            Loc7Img10,
            Loc7Img11,
            Loc7Img12,
            Loc7Img13,
            Loc7Img14
        ],
        titleCode: "location7",
        textBlocks: [
            {
                title: "",
                text: "https://maps.app.goo.gl/gax6hfAvaQSgj3zp7",
                isLink: true
            },
            {
                title: "",
                text: ""
            },
            {
                title: "",
                text: ""
            },
            {
                title: "",
                text: ""
            }
        ],
        coords: {
            lat: 47.9562849,
            lng: 21.6789786
        },
        path: "/locations/6",
        other1: "location1",
        other2: "location2",
        other3: "location3",
        other4: "location4"
    }
};
