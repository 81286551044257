import * as React from "react";
import { useTranslation } from "react-i18next";
import "./Less/RequestForQuotation.css";

export interface RequestForQuotationProps {}

const RequestForQuotation: React.SFC<RequestForQuotationProps> = (props) => {
    const { t } = useTranslation();
    return (
        <a
            href="mailto:info@xanga.hu?subject=Request for quotation"
            className="request-for-quotation"
        >
            {t("requestQuotation")}
        </a>
    );
};

export default RequestForQuotation;
