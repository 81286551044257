import { IAward } from "../Interface/IAward.interface";

export const Awards: IAward[] = [
    {
        date: "2009",
        title: "awardTitle1",
        description: "awardText1"
    },
    {
        date: "2015",
        title: "awardTitle2",
        description: "awardText2"
    },
    {
        date: "2016",
        title: "awardTitle3",
        description: "awardText3"
    }
];
