import { initReactI18next } from "react-i18next";
import Cookie from "js-cookie";
import I18Next from "i18next";
import EN from "./EN.json";
import HU from "./HU.json";

export const InitLanguages = (): void => {
    const allowedLangs: string[] = [ "hu", "en" ];

    let language: string | undefined = Cookie.get("lang");

    const checkLang = () => {
        for (const lang of allowedLangs) {
            //If it doesn't match either of the allowed languages
            //The function will return false and set the lang to the default 'en'.
            if (language === lang) return true;
        }

        return false;
    };

    if (!checkLang()) language = "en";

    I18Next.use(initReactI18next).init({
        resources: {
            en: {
                translation: EN
            },
            hu: {
                translation: HU
            }
        },
        lng: language,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });
};
