import * as React from "react";
import { HashLink } from "react-router-hash-link";
import { Locations } from "../Static/Locations.static";
import { ILocationCard } from "../Interface/ILocationCard.interface";
import { CreateTimeout } from "xa-generics";
import { useTranslation } from "react-i18next";
import HeaderIllustration from "../../../Assets/mainPageIllustartion.png";
import MapWithMarkers from "../../../Assets/map_with_markers.png";
import LocationCard from "./LocationCard";
import References from "./References.view";
import AwardsView from "./Awards.view";
import MapImage from "../../../Assets/mainPageMap.png";
import Marker1 from "../../../Assets/gearMarker1.png";
import Marker2 from "../../../Assets/officeMarker.png";
import Marker3 from "../../../Assets/flightMarker.png";
import Marker4 from "../../../Assets/gearMarker2.png";
import Spinner from "../../Spinner/Spinner";
import Img from "react-image";
import "./Less/MainPage.view.css";
import venturesImage from "../../../Assets/xangaVenturesImage.png";
import VideoBlockView from "./VideoBlock.view";

export interface IMainPageProps {}

const MainPage: React.SFC<IMainPageProps> = () => {
    const { t } = useTranslation();

    const shakeMarker = (domElement: HTMLElement, oldClassName: string) => {
        domElement.className += " add-marker-shaking";

        //Remove the animation-provider classname after 1 second
        CreateTimeout.create_timeout(
            () => {
                domElement.className = oldClassName;
            },
            1000,
            domElement.id
        );
    };

    React.useEffect(() => {
        return () => {
            //If the animation provider timeout was created
            //clean it up before the component unmounts to prevent memory-leak
            CreateTimeout.clear_timeouts();
        };
        //eslint-disable-next-line
    }, []);

    return (
        <div className="main-page-component">
            <div className="main-page-header-section">
                <div className="main-page-header-section__container">
                    <div className="main-page-header-section__container--left">
                        <h1 className="main-page-header__title">{t("mainPageTitle")}</h1>
                        <h2 className="main-page-header__subtitle">{t("mainPageTitle2")}</h2>
                        <p className="main-page-header__desc">{t("mainPageTitle3")}</p>
                        <p className="main-page-header__introduction">
                            <span> {t("mainPageIntroduction1")} </span> {t("mainPageIntroduction2")}
                        </p>
                        <div className="main-page-header__button-container">
                            <HashLink smooth to={"#awards"} className="hash-link">
                                {t("awards")}
                            </HashLink>
                            <HashLink smooth to={"#references"} className="hash-link">
                                {t("referencesTitle")}
                            </HashLink>
                        </div>
                    </div>
                    <div className="main-page-header-section__container--right">
                        <Img src={HeaderIllustration} alt="Illustration" loader={<Spinner />} />
                    </div>
                </div>
            </div>
            <VideoBlockView title={"Xanga Group"} videoUrl={t("mainPageVideoUrl")} />
            <div
                className="main-page-ventures-section"
                style={{ backgroundImage: `url(${venturesImage})` }}
            >
                <div className="main-page-ventures-section__left" />
                <div className="main-page-ventures-section__right">
                    <h1>{t("VenturesTitle1")}</h1>
                    <h2>{t("VenturesTitle2")}</h2>
                    <p>{t("VenturesDescription")}</p>

                    <a
                        href="http://www.xangaventures.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button>{t("VenturesLink")}</button>
                    </a>
                </div>
            </div>
            <div className="main-page-location-section">
                <h2 className="main-page-location-section__title">{t("locations")}</h2>
                <div className="main-page-location-columns">
                    <div className="main-page-location-columns__left">
                        {Locations.map((item: ILocationCard, index: number) => (
                            <LocationCard
                                key={`${index}-location-card`}
                                className={item.className}
                                shakeMarker={shakeMarker}
                                markerID={item.markerID}
                                imageUrl={item.imageUrl}
                                iconUrl={item.iconUrl}
                                title={t(item.title)}
                                link={item.link}
                            />
                        ))}
                    </div>
                    <div className="main-page-location-columns__right">
                        <Img
                            className="main-page-map-with-markers"
                            src={MapWithMarkers}
                            alt="Map with markers"
                            loader={<Spinner />}
                        />
                        <Img
                            className="main-page-location-map"
                            src={MapImage}
                            alt="Map"
                            loader={<Spinner />}
                        />
                        <Img
                            className="main-page-location-marker-1 map-marker"
                            src={Marker4}
                            alt="Marker"
                            id="marker1"
                            loader={<Spinner />}
                        />
                        <Img
                            className="main-page-location-marker-2 map-marker"
                            src={Marker2}
                            alt="Marker"
                            id="marker2"
                            loader={<Spinner />}
                        />
                        <Img
                            className="main-page-location-marker-3 map-marker"
                            src={Marker3}
                            alt="Marker"
                            id="marker3"
                            loader={<Spinner />}
                        />
                        <Img
                            className="main-page-location-marker-4 map-marker"
                            src={Marker1}
                            alt="Marker"
                            id="marker4"
                            loader={<Spinner />}
                        />
                    </div>
                </div>
            </div>
            <AwardsView />
            <References />
        </div>
    );
};

export default MainPage;
