import { IElementDataView } from "../Interface/IElementData.interface";
import { ProjectImages } from "../../../Assets/Projects/ImageLoader";

export const ProjectData: IElementDataView = {
    project1: {
        images: Object.values(ProjectImages.P1),
        titleCode: "cardproj1type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj1developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj1company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj1location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj1size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj1date" }
        ],
        path: "/projects/1",
        other1: "project2",
        other2: "project3"
    },
    project2: {
        images: Object.values(ProjectImages.P2),
        titleCode: "cardproj2type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj2developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj2company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj2location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj2size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj2date" }
        ],
        path: "/projects/2",
        other1: "project1",
        other2: "project3"
    },
    project3: {
        images: Object.values(ProjectImages.P3),
        titleCode: "cardproj3type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj3developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj3company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj3location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj3size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj3date" }
        ],
        path: "/projects/3",
        other1: "project2",
        other2: "project4"
    },
    project4: {
        images: Object.values(ProjectImages.P4),
        titleCode: "cardproj4type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj4developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj4company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj4location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj4size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj4date" }
        ],
        path: "/projects/4",
        other1: "project3",
        other2: "project5"
    },
    project5: {
        images: Object.values(ProjectImages.P5),
        titleCode: "cardproj5type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj5developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj5company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj5location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj5size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj5date" }
        ],
        path: "/projects/5",
        other1: "project4",
        other2: "project6"
    },
    project6: {
        images: Object.values(ProjectImages.P6),
        titleCode: "cardproj6type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj6developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj6company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj6location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj6size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj6date" }
        ],
        path: "/projects/6",
        other1: "project5",
        other2: "project7"
    },
    project7: {
        images: Object.values(ProjectImages.P7),
        titleCode: "cardproj7type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj7developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj7company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj7location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj7size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj7date" }
        ],
        path: "/projects/7",
        other1: "project6",
        other2: "project8"
    },
    project8: {
        images: Object.values(ProjectImages.P8),
        titleCode: "cardproj8type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj8developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj8company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj8location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj8size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj8date" }
        ],
        path: "/projects/8",
        other1: "project7",
        other2: "project9"
    },
    project9: {
        images: Object.values(ProjectImages.P9),
        titleCode: "cardproj9type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj9developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj9company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj9location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj9size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj9date" }
        ],
        path: "/projects/9",
        other1: "project8",
        other2: "project10"
    },
    project10: {
        images: Object.values(ProjectImages.P10),
        titleCode: "cardproj10type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj10developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj10company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj10location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj10size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj10date" }
        ],
        path: "/projects/10",
        other1: "project9",
        other2: "project11"
    },
    project11: {
        images: Object.values(ProjectImages.P11),
        titleCode: "cardproj11type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj11developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj11company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj11location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj11size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj11date" }
        ],
        path: "/projects/11",
        other1: "project10",
        other2: "project12"
    },
    project12: {
        images: Object.values(ProjectImages.P12),
        titleCode: "cardproj12type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj12developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj12company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj12location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj12size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj12date" }
        ],
        path: "/projects/12",
        other1: "project11",
        other2: "project13"
    },
    project13: {
        images: Object.values(ProjectImages.P13),
        titleCode: "cardproj13type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj13developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj13company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj13location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj13size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj13date" }
        ],
        path: "/projects/13",
        other1: "project12",
        other2: "project14"
    },
    project14: {
        images: Object.values(ProjectImages.P14),
        titleCode: "cardproj14type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj14developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj14company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj14location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj14size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj14date" }
        ],
        path: "/projects/14",
        other1: "project13",
        other2: "project15"
    },
    project15: {
        images: Object.values(ProjectImages.P15),
        titleCode: "cardproj15type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj15developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj15company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj15location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj15size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj15date" }
        ],
        path: "/projects/15",
        other1: "project14",
        other2: "project16"
    },
    project16: {
        images: Object.values(ProjectImages.P16),
        titleCode: "cardproj16type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj16developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj16company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj16location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj16size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj16date" }
        ],
        path: "/projects/16",
        other1: "project15",
        other2: "project17"
    },
    project17: {
        images: Object.values(ProjectImages.P17),
        titleCode: "cardproj17type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj17developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj17company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj17location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj17size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj17date" }
        ],
        path: "/projects/17",
        other1: "project16",
        other2: "project18"
    },
    project18: {
        images: Object.values(ProjectImages.P18),
        titleCode: "cardproj18type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj18developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj18company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj18location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj18size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj18date" }
        ],
        path: "/projects/18",
        other1: "project17",
        other2: "project19"
    },
    project19: {
        images: Object.values(ProjectImages.P19),
        titleCode: "cardproj19type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj19developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj19company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj19location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj19size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj19date" }
        ],
        path: "/projects/19",
        other1: "project18",
        other2: "project20"
    },
    project20: {
        images: Object.values(ProjectImages.P20),
        titleCode: "cardproj20type",
        textBlocks: [
            { isSmallTitle: true, title: "cardprojsmt1", text: "cardproj20developFor" },
            { isSmallTitle: true, title: "cardprojsmt2", text: "cardproj20company" },
            { isSmallTitle: true, title: "cardprojsmt3", text: "cardproj20location" },
            { isSmallTitle: true, title: "cardprojsmt4", text: "cardproj20size" },
            { isSmallTitle: true, title: "cardprojsmt7", text: "cardproj20date" }
        ],
        path: "/projects/20",
        other1: "project18",
        other2: "project19"
    }
};
