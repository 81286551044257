import React, { useEffect, useCallback } from "react";
import ImageGallery from "react-image-gallery";
import "./Less/ImageGallery.css";
import { useTranslation } from "react-i18next";

export interface ImageGalleryProps {
    items: string[];
    show: boolean;
    hide: () => void;
}

const ImageGalleryView: React.SFC<ImageGalleryProps> = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        props.show && (document.body.style.overflow = "hidden");
        !props.show && (document.body.style.overflow = "unset");
    }, [props.show]);

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            props.hide();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
        // eslint-disable-next-line
    }, []);

    if (!props.show) return null;

    const images = props.items.map((image) => {
        return {
            original: image,
            thumbnail: image
        };
    });

    return (
        <div className="image-gallery--wrapper" id="id">
            <button onClick={props.hide} className="close-button">
                {t("Close")}
            </button>
            <ImageGallery items={images} showBullets={false} />
        </div>
    );
};

export default ImageGalleryView;
