import React from "react";
import LocationCard from "../../MainPage/View/LocationCard";
import { ILocationCard } from "../../MainPage/Interface/ILocationCard.interface";
import { useTranslation } from "react-i18next";
import { Locations } from "../../MainPage/Static/Locations.static";
import TopImage from "../../../Assets/subpageHeader.png";
import MapImg from "../../../Assets/map_with_markers.png";
import Img from "react-image";
import "./Less/Locations.css";

export interface ILocationsProps {}

const LocationsView: React.FC<ILocationsProps> = (props): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Img src={TopImage} alt="" className="top-img" />
            <div className="locations-wrapper">
                <div className="locations">
                    <div className="locations__left">
                        {Locations.map((item: ILocationCard, index: number) => (
                            <LocationCard
                                key={`${index}-location-card`}
                                className={item.className}
                                imageUrl={item.imageUrl}
                                markerID={item.markerID}
                                iconUrl={item.iconUrl}
                                title={t(item.title)}
                                link={item.link}
                            />
                        ))}
                    </div>
                    <div className="locations__right">
                        <Img src={MapImg} alt="Map with markers" className="map" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default LocationsView;
