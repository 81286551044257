import React from "react";
import { useTranslation } from "react-i18next";
import { ReferenceImages } from "../Static/References.static";
import Spinner from "../../Spinner/Spinner";
import Img from "react-image";
import "./Less/References.css";

const References: React.FC<{}> = (): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="references" id="references">
            <div className="references__title">{t("referencesTitle")}</div>
            <div className="references__container">
                {ReferenceImages.map((imgUrl: string, index: number) => (
                    <div className="references__image-container" key={`${index}-ref-image`}>
                        <Img src={imgUrl} alt={imgUrl} className="image" loader={<Spinner />} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default References;
