import i1 from "./AssaAbloy.png";
import i2 from "./cola_logo.png";
import i3 from "./dpd.png";
import i4 from "./Gravic.png";
import i5 from "./lufthansa.png";
import i6 from "./manz.png";
import i8 from "./orelikon.png";
import i9 from "./orszagos_dohanyboltellato.png";
import i10 from "./popz.png";
import i11 from "./mst.png";
import i12 from "./RRD.png";
import i14 from "./Transcomos.png";
import i15 from "./vizmuvek.png";
import i16 from "./Wizz.png";
import i17 from "./burkle.png";

export const partner_images = [i1, i2, i3, i4, i5, i6, i8, i9, i12, i14, i15, i16, i10, i11, i17];
