import React from "react";
import { ILocationCard } from "../Interface/ILocationCard.interface";
import { useHistory } from "react-router-dom";
import { IHistory } from "xa-generics";
import Spinner from "../../Spinner/Spinner";
import Img from "react-image";
import "./Less/LocationCard.css";

export interface ILocationCardProps extends ILocationCard {
    shakeMarker?: (domElement: HTMLElement, className: string) => void;
}

const LocationCard: React.SFC<ILocationCardProps> = (props: ILocationCardProps) => {
    const history: IHistory = useHistory();
    return (
        <div
            className="location-card-component"
            onMouseEnter={() => {
                const element: HTMLElement | null = document.getElementById(props.markerID);
                if (element && props.shakeMarker) props.shakeMarker(element, props.className);
            }}
            onClick={() => history.push(props.link)}
        >
            <div className="location-card__image-container">
                <Img
                    className="location-card__image"
                    alt={props.title}
                    src={props.imageUrl}
                    loader={<Spinner />}
                />
            </div>
            <div className="location-card__body">
                <Img
                    className="location-card__icon"
                    src={props.iconUrl}
                    alt="Location icon"
                    loader={<Spinner />}
                />
                <p className="location-card__title">{props.title}</p>
                <div className="arrow-root">
                    <span className="arrow" />
                </div>
            </div>
        </div>
    );
};

export default LocationCard;
