import { ILocationCard } from "./../Interface/ILocationCard.interface";
import LocationImage1 from "../../../Assets/Locations/loc1_img1.jpg";
import LocationImage2 from "../../../Assets/Locations/location2.jpg";
import LocationImage3 from "../../../Assets/Locations/loc3_img2.jpg";
import LocationImage4 from "../../../Assets/Locations/loc4_img2.jpg";
import LocationImage6 from "../../../Assets/Locations/loc6_img1.jpg";
import LocationImage7 from "../../../Assets/Locations/loc7_img1.jpg";
import IconUrl1 from "../../../Assets/gearCircle1.png";
import IconUrl2 from "../../../Assets/officeCircle.png";
import IconUrl3 from "../../../Assets/flightCircle.png";
import IconUrl4 from "../../../Assets/gearCircle2.png";

export const Locations: ILocationCard[] = [
    {
        className: "main-page-location-marker-1 map-marker",
        imageUrl: LocationImage1,
        iconUrl: IconUrl4,
        title: "location1",
        link: "/locations/1",
        markerID: "marker1"
    },
    {
        className: "main-page-location-marker-2 map-marker",
        imageUrl: LocationImage2,
        iconUrl: IconUrl2,
        title: "location2",
        link: "/locations/2",
        markerID: "marker2"
    },
    {
        className: "main-page-location-marker-3 map-marker",
        imageUrl: LocationImage3,
        iconUrl: IconUrl3,
        title: "location3",
        link: "/locations/3",
        markerID: "marker3"
    },
    {
        className: "main-page-location-marker-4 map-marker",
        imageUrl: LocationImage4,
        iconUrl: IconUrl1,
        title: "location4",
        link: "/locations/4",
        markerID: "marker4"
    },
    {
        className: "main-page-location-marker-4 map-marker",
        imageUrl: LocationImage6,
        iconUrl: IconUrl1,
        title: "location6",
        link: "/locations/6", //ez azért nem 5 mert az 5. route-on szerepel egy "debreceni fejlesztési alap" oldal ami nagyon úgy néz ki hogy valid és direkt van ott
        markerID: "marker4"
    },
    {
        className: "main-page-location-marker-4 map-marker",
        imageUrl: LocationImage7,
        iconUrl: IconUrl1,
        title: "location7",
        link: "/locations/7",
        markerID: "marker4"
    }
];
