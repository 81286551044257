import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Facebook from "../../../Assets/facebook.png";
import LinkedIn from "../../../Assets/linkedin.png";
import "./Less/News.css";

export interface INewsPageProps {}

const NewsPage: React.FC<INewsPageProps> = (props): React.ReactElement => {
    const { t } = useTranslation();
    const [fbElement, set_fbElement] = useState<React.ReactElement | null>(null);

    useEffect(() => {
        const el = document.getElementById("fb-container");

        const scriptTag = document.createElement("script");
        scriptTag.src = "https://connect.facebook.net/hu_HU/sdk.js#xfbml=1&version=v5.0";
        if (el) el.appendChild(scriptTag);

        scriptTag.onload = () => {
            const element: React.ReactElement = (
                <div style={{ width: "420px" }}>
                    <div
                        className="fb-page"
                        data-href="https://www.facebook.com/XangaInvestmentDevelopmentGroup/"
                        data-tabs="timeline"
                        data-width="420"
                        data-height="600"
                        data-small-header="true"
                        data-adapt-container-width="true"
                        data-hide-cover="true"
                        data-show-facepile="false"
                    />
                </div>
            );
            set_fbElement(element);
            window["FB"]["XFBML"].parse();
        };
    }, []);

    return (
        <div className="news-container">
            <div className="news">
                <div className="news__left-block">
                    <h1 className="news__title">{t("newsTitle")}</h1>
                    <h3 className="news__title-small">{t("newsTitleSmall")}</h3>
                    <div className="news__text">{t("newsText")}</div>
                    <div className="news__url">
                        <img src={LinkedIn} alt="LinkedIn" />
                        <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href="https://www.linkedin.com/company/xanga-investment-development-group"
                        >
                            {t("followUs")}
                        </a>
                    </div>
                    <div className="news__url">
                        <img src={Facebook} alt="FB" />
                        <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href="https://www.facebook.com/XangaInvestmentDevelopmentGroup/"
                        >
                            https://www.facebook.com/XangaInvestmentDevelopmentGroup/
                        </a>
                    </div>
                </div>
                <div className="news__right-block" id="fb-container">
                    {fbElement}
                </div>
            </div>
        </div>
    );
};

export default NewsPage;
