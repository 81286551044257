import { ILeaseCard } from "../Interface/ILeaseCard.interface";
import { LeaseImages } from "../../../Assets/Leases/ImageLoader";

export const Leases: ILeaseCard[] = [
    {
        name: "leasecard1name",
        location: "leasecard1location",
        total_office_area: "leasecard1total_area",
        rentable_area: "leasecard1rentable_area",
        floors: "leasecard1floors",
        completion: "leasecard1completion",
        images: LeaseImages.L1,
        id: 1
    },
    {
        name: "leasecard2name",
        location: "leasecard2location",
        total_warehouse_area: "leasecard2total_area",
        rentable_area: "leasecard2rentable_area",
        completion: "leasecard2completion",
        images: LeaseImages.L2,
        id: 2
    },
    {
        name: "leasecard3name",
        location: "leasecard3location",
        total_office_area: "leasecard3total_area",
        rentable_area: "leasecard3rentable_area",
        floors: "leasecard3floors",
        completion: "leasecard3completion",
        images: LeaseImages.L3,
        id: 3
    },
    {
        name: "leasecard4name",
        location: "leasecard4location",
        total_warehouse_area: "leasecard4total_area",
        rentable_area: "leasecard4rentable_area",
        completion: "leasecard4completion",
        images: LeaseImages.L4,
        id: 4
    },
    {
        name: "leasecard5name",
        location: "leasecard5location",
        total_warehouse_area: "leasecard5total_area",
        rentable_area: "leasecard5rentable_area",
        images: LeaseImages.L5,
        id: 5
    },
    {
        name: "leasecard6name",
        location: "leasecard6location",
        total_warehouse_area: "leasecard6total_area",
        rentable_area: "leasecard6rentable_area",
        images: LeaseImages.L6,
        id: 6
    }
];
