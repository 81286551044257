import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./Less/BackButton.css";

export interface BackButtonProps {
    to: string;
}

const BackButton: React.SFC<BackButtonProps> = (props) => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div onClick={() => history.goBack()}>
            <button className="back-button">{t("Back")}</button>
        </div>
    );
};

export default BackButton;
