import { IApplicationCard } from "./../Interface/IApplicationCard.interface";

export const Applications: IApplicationCard[] = [
    {
        className: "main-page-location-marker-1 map-marker",
        title: "application1",
        link: "/applications/1"
    },
    {
        className: "main-page-location-marker-2 map-marker",
        title: "application2",
        link: "/applications/2"
    },
    {
        className: "main-page-location-marker-3 map-marker",
        title: "application3",
        link: "/applications/3"
    }
];
