import React from "react";
import { IApplicationCard } from "../Interface/IApplicationCard.interface";
import { useHistory } from "react-router-dom";
import { IHistory } from "xa-generics";
import "./Less/LocationCard.css";

export interface IApplicationCardProps extends IApplicationCard {
    shakeMarker?: (domElement: HTMLElement, className: string) => void;
}

const ApplicationCard: React.SFC<IApplicationCardProps> = (props: IApplicationCardProps) => {
    const history: IHistory = useHistory();
    return (
        <div className="location-card-component" onClick={() => history.push(props.link)}>
            <div className="location-card__body">
                <p className="location-card__title">{props.title}</p>
                <div className="arrow-root">
                    <span className="arrow" />
                </div>
            </div>
        </div>
    );
};

export default ApplicationCard;
