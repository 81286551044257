import React from "react";
import { useTranslation } from "react-i18next";
import { CardData } from "../Static/CardData.static";
import { ICardData } from "../Interface/ICardData.interface";
import MapImage from "../../../Assets/contact_marker_map.png";
import Spinner from "../../Spinner/Spinner";
import Img from "react-image";
import "./Less/Contact.css";

export interface IContactUsProps {}

const ContactUs: React.FC<IContactUsProps> = (props): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="contact-container">
            <div className="contact">
                <div className="contact__title">{t("contactTitle")}</div>
                <div className="contact__possibilities">
                    <div className="contact__possibilities--email">
                        <h2 className="contact__title-small">Email</h2>
                        <div className="email">info@xanga.hu</div>
                    </div>
                    {/* <div className="contact__possibilities--linkedin">
						<h2 className="contact__title-small">LinkedIn</h2>
						<a
							href="https://www.linkedin.com/company/xanga-investment-development-group"
							className="link"
							target="_blank"
							rel="noopener noreferrer"
						>
							Follow us
						</a>
					</div> */}
                </div>
                <div className="contact__cards">
                    {CardData.map((card: ICardData, index: number) => (
                        <div className="contact__card" key={`${index}-card-element`}>
                            <div className="contact__card--header">{t(card.header)}</div>
                            <div className="contact__card--body">
                                <Img
                                    src={card.image}
                                    alt={card.header}
                                    loader={<Spinner />}
                                    className="icon"
                                />
                                <div className="__address">{card.address}</div>
                                <div className="__block">
                                    <div className="__block--title">{t("contactCardFax")}</div>
                                    <div className="__block--text">{card.fax || "-"}</div>
                                </div>
                                <div className="__block">
                                    <div className="__block--title">{t("contactCardPhone")}</div>
                                    <div className="__block--text">{card.phone || "-"}</div>
                                </div>
                                <div className="__block">
                                    <div className="__block--title">{t("contactCardEmail")}</div>
                                    <div className="__block--text">{card.email || "-"}</div>
                                </div>
                                <div className="__block">
                                    <div className="__block--title">{t("contactCardWeb")}</div>
                                    {card.web ? (
                                        <a
                                            className="__block--text link"
                                            href={card.web}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {card.web || "-"}
                                        </a>
                                    ) : (
                                        <div className="__block--text">-</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="contact__map-container">
                    <Img src={MapImage} alt="Map" className="map-image" loader={<Spinner />} />
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
