import React from "react";
import { Projects } from "../Static/Projects.static";
import { IHistory } from "xa-generics";
import { useHistory } from "react-router-dom";
import { IProjectCard } from "../Interface/IProjectCard.interface";
import { useTranslation } from "react-i18next";
import Spinner from "../../Spinner/Spinner";
import Image from "react-image";
import "./Less/Projects.css";

export interface IProjectsViewProps {}

const ProjectsView: React.FC<IProjectsViewProps> = (props): React.ReactElement => {
    const { t } = useTranslation();
    const history: IHistory = useHistory();
    return (
        <div className="projects-wrapper">
            <div className="projects">
                <div className="projects__title">{t("projectsTitle")}</div>
                <div className="projects__container">
                    {Projects.map((project: IProjectCard, index: number) => (
                        <div
                            className="card-container"
                            key={`${index}-project-card`}
                            onClick={() => history.push(`/projects/${project.id}`)}
                        >
                            <div className="project-card">
                                <div className="project-card__header">{t(project.type)}</div>
                                <div className="project-card__image">
                                    <Image
                                        src={project.image}
                                        alt={project.type}
                                        loader={<Spinner />}
                                        className="project-card__image--element"
                                    />
                                    <div className="project-card__sub-title">
                                        {t(project.location)}
                                    </div>
                                </div>
                                <div className="project-card__body">
                                    <div className="project-card__body--investment">
                                        {t(project.investment)}
                                    </div>
                                    <div className="project-card__body--company">
                                        {t(project.company)}
                                    </div>
                                    <div className="project-card__body--date">
                                        {t(project.date)}
                                    </div>
                                </div>
                                <div className="project-card__footer">
                                    <div className="arrow-root">
                                        <span className="arrow" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProjectsView;
