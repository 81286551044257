//P1 images
import P1I1 from "./proj1_img1.jpg";
import P1I2 from "./proj1_img2.jpg";
import P1I3 from "./proj1_img3.jpg";
import P1I4 from "./proj1_img4.jpg";
import P1I5 from "./proj1_img5.jpg";
import P1I6 from "./proj1_img6.jpg";
import P1I7 from "./proj1_img7.jpg";
import P1I8 from "./proj1_img8.jpg";
import P1I9 from "./proj1_img9.jpg";
import P1I10 from "./proj1_img10.jpg";
import P1I11 from "./proj1_img11.jpg";
import P1I12 from "./proj1_img12.jpg";
import P1I13 from "./proj1_img13.jpg";
import P1I14 from "./proj1_img14.jpg";
import P1I15 from "./proj1_img15.jpg";
import P1I16 from "./proj1_img16.jpg";
//P2 images
import P2I1 from "./proj2_img1.jpg";
import P2I2 from "./proj2_img2.jpg";
import P2I3 from "./proj2_img3.jpg";
import P2I4 from "./proj2_img4.jpg";
import P2I5 from "./proj2_img5.jpg";
import P2I6 from "./proj2_img6.jpg";
import P2I7 from "./proj2_img7.jpg";
import P2I8 from "./proj2_img8.jpg";
//P3 images
import P3I1 from "./proj3_img1.jpg";
import P3I2 from "./proj3_img2.jpg";
import P3I3 from "./proj3_img3.jpg";
//P4 images
import P4I1 from "./proj4_img1.jpg";
import P4I3 from "./proj4_img3.jpg";
import P4I4 from "./proj4_img4.jpg";
import P4I5 from "./proj4_img5.jpg";
import P4I6 from "./proj4_img6.jpg";
import P4I7 from "./proj4_img7.jpg";
import P4I8 from "./proj4_img8.jpg";
import P4I9 from "./proj4_img9.jpg";
//P5 images
import P5I1 from "./proj5_img1.jpg";
import P5I3 from "./proj5_img3.jpg";
//P6 images
import P6I1 from "./proj6_img1.jpg";
import P6I2 from "./proj6_img2.jpg";
import P6I3 from "./proj6_img3.jpg";
//P7 images
import P7I1 from "./proj7_img1.jpg";
import P7I2 from "./proj7_img2.jpg";
import P7I3 from "./proj7_img3.jpg";
//P8 images
import P8I2 from "./proj8_img2.jpg";
import P8I3 from "./proj8_img3.jpg";
import P8I4 from "./proj8_img4.jpg";
import P8I5 from "./proj8_img5.jpg";
//P9 images
import P9I1 from "./proj9_img1.jpg";
import P9I2 from "./proj9_img2.jpg";
import P9I3 from "./proj9_img3.jpg";
//P10 images
import P10I1 from "./proj10_img1.jpg";
import P10I2 from "./proj10_img2.jpg";
import P10I3 from "./proj10_img3.jpg";
//P11 images
import P11I1 from "./proj11_img1.jpg";
import P11I2 from "./proj11_img2.jpg";
import P11I3 from "./proj11_img3.jpg";
//P12 images
import P12I1 from "./proj12_img1.jpg";
import P12I2 from "./proj12_img2.jpg";
//P13 images
import P13I1 from "./proj13_img1.jpg";
import P13I2 from "./proj13_img2.jpg";
//P14 images
import P14I1 from "./proj14_img1.jpg";
import P14I2 from "./proj14_img2.jpg";
//P15 images
import P15I1 from "./proj15_img1.jpg";
import P15I2 from "./proj15_img2.jpg";
import P15I3 from "./proj15_img3.jpg";
import P15I4 from "./proj15_img4.jpg";
import P15I5 from "./proj15_img5.jpg";
import P15I6 from "./proj15_img6.jpg";
import P15I7 from "./proj15_img7.jpg";
import P15I8 from "./proj15_img8.jpg";
import P15I9 from "./proj15_img9.jpg";
import P15I10 from "./proj15_img10.jpg";
import P15I11 from "./proj15_img11.jpg";
import P15I12 from "./proj15_img12.jpg";
import P15I13 from "./proj15_img13.jpg";
import P15I14 from "./proj15_img14.jpg";
//P16 images
import P16I1 from "./proj16_img1.jpg";
//P17 images
import P17I1 from "./proj17_img1.jpg";
import P17I2 from "./proj17_img2.jpg";
import P17I3 from "./proj17_img3.jpg";
import P17I4 from "./proj17_img4.jpg";
import P17I5 from "./proj17_img5.jpg";
//P18 images
import P18I1 from "./proj18_img1.jpg";
import P18I2 from "./proj18_img2.jpg";
import P18I3 from "./proj18_img3.jpg";
import P18I4 from "./proj18_img3.jpg";
import P18I5 from "./proj18_img5.jpg";
//P19 images
import P19I1 from "./proj19_img1.jpg";
import P19I2 from "./proj19_img2.jpg";
import P19I3 from "./proj19_img3.jpg";
import P19I4 from "./proj19_img4.jpg";
import P19I5 from "./proj19_img5.jpg";
import P19I6 from "./proj19_img6.jpg";
import P19I7 from "./proj19_img7.jpg";
import P19I8 from "./proj19_img8.jpg";
import P19I9 from "./proj19_img9.jpg";
import P19I10 from "./proj19_img10.jpg";
//P20 images
import P20I2 from "./proj20_img2.jpg";
import P20I3 from "./proj20_img3.jpg";
import P20I4 from "./proj20_img4.jpg";
import P20I5 from "./proj20_img5.jpg";
import P20I6 from "./proj20_img6.jpg";
import P20I7 from "./proj20_img7.jpg";
import P20I8 from "./proj20_img8.jpg";
import P20I9 from "./proj20_img9.jpg";
import P20I10 from "./proj20_img10.jpg";
import P20I11 from "./proj20_img11.jpg";
import P20I12 from "./proj20_img12.jpg";
import P20I13 from "./proj20_img13.jpg";
import P20I14 from "./proj20_img14.jpg";
import P20I15 from "./proj20_img15.jpg";
import P20I16 from "./proj20_img16.jpg";
import P20I17 from "./proj20_img17.jpg";
import P20I18 from "./proj20_img18.jpg";
import P20I19 from "./proj20_img19.jpg";
import P20I20 from "./proj20_img20.jpg";
import P20I21 from "./proj20_img21.jpg";

export const ProjectImages = {
    P1: {
        P1I1,
        P1I2,
        P1I3,
        P1I4,
        P1I5,
        P1I6,
        P1I7,
        P1I8,
        P1I9,
        P1I10,
        P1I11,
        P1I12,
        P1I13,
        P1I14,
        P1I15,
        P1I16
    },
    P2: {
        P2I1,
        P2I2,
        P2I3,
        P2I4,
        P2I5,
        P2I6,
        P2I7,
        P2I8
    },
    P3: {
        P3I1,
        P3I2,
        P3I3
    },
    P4: {
        P4I1,
        P4I3,
        P4I4,
        P4I5,
        P4I6,
        P4I7,
        P4I8,
        P4I9
    },
    P5: {
        P5I1,
        P5I3
    },
    P6: {
        P6I1,
        P6I2,
        P6I3
    },
    P7: {
        P7I1,
        P7I2,
        P7I3
    },
    P8: {
        P8I2,
        P8I3,
        P8I4,
        P8I5
    },
    P9: {
        P9I1,
        P9I2,
        P9I3
    },
    P10: {
        P10I1,
        P10I2,
        P10I3
    },
    P11: {
        P11I1,
        P11I2,
        P11I3
    },
    P12: {
        P12I1,
        P12I2
    },
    P13: {
        P13I1,
        P13I2
    },
    P14: {
        P14I1,
        P14I2
    },
    P15: {
        P15I1,
        P15I2,
        P15I3,
        P15I4,
        P15I5,
        P15I6,
        P15I7,
        P15I8,
        P15I9,
        P15I10,
        P15I11,
        P15I12,
        P15I13,
        P15I14
    },
    P16: {
        P16I1
    },
    P17: {
        P17I1,
        P17I2,
        P17I3,
        P17I4,
        P17I5
    },
    P18: {
        P18I1,
        P18I2,
        P18I3,
        P18I4,
        P18I5
    },
    P19: {
        P19I1,
        P19I2,
        P19I3,
        P19I4,
        P19I5,
        P19I6,
        P19I7,
        P19I8,
        P19I9,
        P19I10
    },
    P20: {
        P20I2,
        P20I3,
        P20I4,
        P20I5,
        P20I6,
        P20I7,
        P20I8,
        P20I9,
        P20I10,
        P20I11,
        P20I12,
        P20I13,
        P20I14,
        P20I15,
        P20I16,
        P20I17,
        P20I18,
        P20I19,
        P20I20,
        P20I21
    }
};
