import { IProjectCard } from "../Interface/IProjectCard.interface";
import { ProjectImages } from "../../../Assets/Projects/ImageLoader";

export const Projects: IProjectCard[] = [
    {
        location: "cardproj1location",
        type: "cardproj1type",
        investment: "cardproj1investment",
        company: "cardproj1company",
        date: "cardproj1date",
        image: ProjectImages.P1.P1I1,
        id: 1
    },
    {
        location: "cardproj2location",
        type: "cardproj2type",
        investment: "cardproj2investment",
        company: "cardproj2company",
        date: "cardproj2date",
        image: ProjectImages.P2.P2I1,
        id: 2
    },
    {
        location: "cardproj3location",
        type: "cardproj3type",
        investment: "cardproj3investment",
        company: "cardproj3company",
        date: "cardproj3date",
        image: ProjectImages.P3.P3I1,
        id: 3
    },
    {
        location: "cardproj4location",
        type: "cardproj4type",
        investment: "cardproj4investment",
        company: "cardproj4company",
        date: "cardproj4date",
        image: ProjectImages.P4.P4I1,
        id: 4
    },
    {
        location: "cardproj5location",
        type: "cardproj5type",
        investment: "cardproj5investment",
        company: "cardproj5company",
        date: "cardproj5date",
        image: ProjectImages.P5.P5I1,
        id: 5
    },
    {
        location: "cardproj6location",
        type: "cardproj6type",
        investment: "cardproj6investment",
        company: "cardproj6company",
        date: "cardproj6date",
        image: ProjectImages.P6.P6I1,
        id: 6
    },
    {
        location: "cardproj7location",
        type: "cardproj7type",
        investment: "cardproj7investment",
        company: "cardproj7company",
        date: "cardproj7date",
        image: ProjectImages.P7.P7I1,
        id: 7
    },
    {
        location: "cardproj8location",
        type: "cardproj8type",
        investment: "cardproj8investment",
        company: "cardproj8company",
        date: "cardproj8date",
        image: ProjectImages.P8.P8I2,
        id: 8
    },
    {
        location: "cardproj9location",
        type: "cardproj9type",
        investment: "cardproj9investment",
        company: "cardproj9company",
        date: "cardproj9date",
        image: ProjectImages.P9.P9I1,
        id: 9
    },
    {
        location: "cardproj10location",
        type: "cardproj10type",
        investment: "cardproj10investment",
        company: "cardproj10company",
        date: "cardproj10date",
        image: ProjectImages.P10.P10I1,
        id: 10
    },
    {
        location: "cardproj11location",
        type: "cardproj11type",
        investment: "cardproj11investment",
        company: "cardproj11company",
        date: "cardproj11date",
        image: ProjectImages.P11.P11I1,
        id: 11
    },
    {
        location: "cardproj12location",
        type: "cardproj12type",
        investment: "cardproj12investment",
        company: "cardproj12company",
        date: "cardproj12date",
        image: ProjectImages.P12.P12I1,
        id: 12
    },
    {
        location: "cardproj13location",
        type: "cardproj13type",
        investment: "cardproj13investment",
        company: "cardproj13company",
        date: "cardproj13date",
        image: ProjectImages.P13.P13I1,
        id: 13
    },
    {
        location: "cardproj14location",
        type: "cardproj14type",
        investment: "cardproj14investment",
        company: "cardproj14company",
        date: "cardproj14date",
        image: ProjectImages.P14.P14I1,
        id: 14
    },
    {
        location: "cardproj15location",
        type: "cardproj15type",
        investment: "cardproj15investment",
        company: "cardproj15company",
        date: "cardproj15date",
        image: ProjectImages.P15.P15I1,
        id: 15
    },
    {
        location: "cardproj16location",
        type: "cardproj16type",
        investment: "cardproj16investment",
        company: "cardproj16company",
        date: "cardproj16date",
        image: ProjectImages.P16.P16I1,
        id: 16
    },
    {
        location: "cardproj17location",
        type: "cardproj17type",
        investment: "cardproj17investment",
        company: "cardproj17company",
        date: "cardproj17date",
        image: ProjectImages.P17.P17I1,
        id: 17
    },
    {
        location: "cardproj18location",
        type: "cardproj18type",
        investment: "cardproj18investment",
        company: "cardproj18company",
        date: "cardproj18date",
        image: ProjectImages.P18.P18I1,
        id: 18
    },
    {
        location: "cardproj19location",
        type: "cardproj19type",
        investment: "cardproj19investment",
        company: "cardproj19company",
        date: "cardproj19date",
        image: ProjectImages.P19.P19I1,
        id: 19
    },
    {
        location: "cardproj20location",
        type: "cardproj20type",
        investment: "cardproj20investment",
        company: "cardproj20company",
        date: "cardproj20date",
        image: ProjectImages.P20.P20I4,
        id: 20
    }
];
