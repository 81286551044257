import React from "react";
import { useTranslation } from "react-i18next";
import "./Less/Applications.css";
import { Applications } from "../../MainPage/Static/Applications.static";
import { IApplicationCard } from "../../MainPage/Interface/IApplicationCard.interface";
import ApplicationCard from "../../MainPage/View/ApplicationCard";

export interface IApplicationsProps {}

const ApplicationsView: React.FC<IApplicationsProps> = (props): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="about-us">
            <div className="about-us__container">
                <div className="about-us__container--left">
                    {Applications.map((item: IApplicationCard, index: number) => (
                        <ApplicationCard
                            key={`${index}-location-card`}
                            className={item.className}
                            title={t(item.title)}
                            link={item.link}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ApplicationsView;
